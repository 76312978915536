import styled from "styled-components";
import { ViewCommon, TextCommon, CheckBoxCommon, ButtonCommon } from "components/index";
import { colors } from "theme/index";

export const Container = styled(ViewCommon)`
  padding: 20px;
`;

export const View = styled(ViewCommon)`
  flex-direction: row;
`;

export const TextLabel = styled(TextCommon)`
  font-size: 17px;
  color: ${colors.Black};
  font-family: "AvertaStdCY-Semibold" !important;
  margin-bottom: 20px;
`;

export const CheckBox = styled(CheckBoxCommon)`
  margin-right: 10px;
`;

export const Button = styled(ButtonCommon)`
  
`;