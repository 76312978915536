import styled from "styled-components";
import { colors } from "theme/index";
import { ViewCommon, TextCommon } from "components/index";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  header: {
    height: 60,
  },
  accordion: {
    // margin: '0 !important',
    // boxShadow: '10px 12px 11px 11px rgba(0,0,0,0.2)'
  },
}));

export const Container = styled(ViewCommon)`
  background: ${colors.White};
`;

export const BoxQuestion = styled(ViewCommon)`
  background: ${colors.White};
  justify-content: center;
  align-items: center;
  margin: 20px;
`;

export const Img = styled.img`
  min-width: 22px;
  min-height: 22px;
  margin: 50px;
  position: fixed;
  bottom: 0;
  right: 0;
`;

export const Text = styled(TextCommon)`
  color: ${colors.Black};
  font-size: 15px;
  font-weight: bold;
  font-family: "AvertaStdCY-Bold" !important;
`;

export const TextHeader = styled(TextCommon)`
  color: ${colors.Primary};
  font-size: 15px;
  font-weight: bold;
  font-family: "AvertaStdCY-Bold" !important;
`;

export const TextDetails = styled(TextCommon)`
  color: ${colors.Black};
  font-size: 15px;
`;
