import styled from "styled-components";
import { colors } from "theme/index";
import { ViewCommon, TextCommon } from "components/index";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

export const View = styled(ViewCommon)``;

export const Container = styled(ViewCommon)`
  overflow-x: hidden;
  overflow-y: hidden;

  background-color: ${colors.White};
`;

export const AntTabs = withStyles({
  root: {
    margin: "20px",
    border: "0px solid transparent",
    borderRadius: "18px",
    backgroundColor: "white",
    minHeight: "36px",
  },
  indicator: {
    backgroundColor: "white",
    opacity: 0,
    display: "none",
  },
})(Tabs);

export const AntTab = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid transparent",
    padding: "5px 0px 5px 0px",
    backgroundColor: "white",
    color: "black",
    textAlign: "center",
    fontSize: "13px",
    textTransform: "none",
    fontFamily: "AvertaStdCY-Bold",
    minHeight: "36px",
    // fontStyle: "normal",
    // width: "111px",
    // height: "36px",
  },
  selected: {
    color: "white",
    borderBottom: "0",
    background: colors.Primary,
    borderRadius: "18px",
    fontSize: "13px",
    fontFamily: "AvertaStdCY-Bold",
    // fontStyle: "normal",
    textTransform: "none",
    // width: "111px",
    // height: "36px",
    minHeight: "36px"
  },
}))((props) => <Tab disableRipple {...props} />);
