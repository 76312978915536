import styled from "styled-components";
import { ViewCommon, TextCommon } from "components/index";

export const Container = styled(ViewCommon)``;

export const View = styled(ViewCommon)``;

export const Text = styled(TextCommon)`
  font-size: 13px;
  margin-top: 20px;
`;

export const TextBold = styled(Text)`
  font-weight: bold;
`;
