import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import * as ROUTES from "constants/routes";
import HomeContainer from "containers/home/index";
import OrdersContainer from "containers/orders/index";
import WithdrawContainer from "containers/withdraw/index";
import SupportContainer from "containers/support/index";
import NotePreOrderContainer from "containers/note-preorder/index";
import NotePreOrderProcessContainer from "containers/note-preorder-process/index";
import FAQsContainer from "containers/faqs/index";
import BrandsDetailContainer from "containers/brands-detail/index";
import SearchContainer from "containers/search/index";
import WithdrawRequiredContainer from "containers/withdraw-required/index";
import WithdrawConfirmContainer from "containers/withdraw-confirm/index";
import WithdrawCompletedContainer from "containers/withdraw-completed/index";
import OrderDetailContainer from "containers//order-detail/index";
import DealsListContainer from "containers/deals-list/index";
import DealsNoteContainer from "containers/deals-note/index";

export default function AppContainer() {
  return (
    <Router>
      <Switch>
        <Route exact path={ROUTES.HOME} component={HomeContainer} />
        <Route exact path={ROUTES.ORDERS} component={OrdersContainer} />
        <Route exact path={ROUTES.WITHDRAW} component={WithdrawContainer} />
        <Route exact path={ROUTES.SUPPORT} component={SupportContainer} />
        <Route
          exact
          path={ROUTES.NOTE_PREORDER}
          component={NotePreOrderContainer}
        />
        <Route
          exact
          path={ROUTES.NOTE_PREORDER_PROCESS}
          component={NotePreOrderProcessContainer}
        />
        <Route exact path={ROUTES.SUPPORT_FAQs} component={FAQsContainer} />
        <Route
          exact
          path={ROUTES.BRANDS_DETAIL}
          component={BrandsDetailContainer}
        />
        <Route exact path={ROUTES.SEARCH} component={SearchContainer} />
        <Route
          exact
          path={ROUTES.DEALS_NOTE}
          component={DealsNoteContainer}
        />
        <Route
          exact
          path={ROUTES.WITHDRAW_REQUIRED}
          component={WithdrawRequiredContainer}
        />
        <Route
          exact
          path={ROUTES.WITHDRAW_CONFIRM}
          component={WithdrawConfirmContainer}
        />
        <Route
          exact
          path={ROUTES.WITHDRAW_COMPLETED}
          component={WithdrawCompletedContainer}
        />
        <Route
          exact
          path={ROUTES.ORDER_DETAIL}
          component={OrderDetailContainer}
        />
        <Route
          exact
          path={ROUTES.DEALS_LIST}
          component={DealsListContainer}
        />
      </Switch>
    </Router>
  );
}
