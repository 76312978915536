import React from "react";
import {
  ViewScheduleDetail,
  ViewScheduleDetailHeader,
  LineStraight,
  ViewScheduleDetailFooter,
  TextScheduleTitleFooter,
  TextScheduleValueFooter,
} from "./index.style";
import { CheckCircle, Cancel, RadioButtonUnchecked } from "@material-ui/icons";

const Status = (props) => {
  const [status, setStatus] = React.useState(() => {
    if (props.type === "waiting") {
      return 0;
    } else if (props.type === "done") {
      return 1;
    } else {
      return 2;
    }
  });

  const _renderSchedule = () => {
    let colorValue = "orange";
    switch (status) {
      case 0:
        colorValue = "orange";
        break;
      case 1:
        colorValue = "blue";
        break;
      case 2:
        colorValue = "red";
        break;
      default:
        break;
    }

    return (
      <ViewScheduleDetailHeader>
        <CheckCircle
          style={{ color: colorValue, width: "16px", height: "16px" }}
        />
        <LineStraight style={{ background: colorValue }} />
        <CheckCircle
          style={{ color: colorValue, width: "16px", height: "16px" }}
        />
        <LineStraight style={{ background: colorValue }} />
        {_renderIconFollowStatus(status)}
      </ViewScheduleDetailHeader>
    );
  };

  const _renderIconFollowStatus = (param) => {
    switch (param) {
      case 0:
        return (
          <RadioButtonUnchecked
            style={{ color: "orange", width: "16px", height: "16px" }}
          />
        );
      case 1:
        return (
          <CheckCircle
            style={{ color: "blue", width: "16px", height: "16px" }}
          />
        );
      case 2:
        return (
          <Cancel style={{ color: "red", width: "16px", height: "16px" }} />
        );
      default:
        return;
    }
  };

  return (
    <ViewScheduleDetail>
      {_renderSchedule()}
      <ViewScheduleDetailFooter>
        <TextScheduleTitleFooter>Mua hàng</TextScheduleTitleFooter>
        <TextScheduleValueFooter>05-12-2019, 13:50</TextScheduleValueFooter>
        <TextScheduleTitleFooter>Ghi nhận thành công</TextScheduleTitleFooter>
        <TextScheduleValueFooter>05-12-2019, 14:50</TextScheduleValueFooter>
        <TextScheduleTitleFooter>
          {status === 2 ? "Đơn hàng đã huỷ" : "Hoàn tiền"}
        </TextScheduleTitleFooter>
        {status === 2 ? (
          <TextScheduleValueFooter>05-2-2020, 14:49</TextScheduleValueFooter>
        ) : (
          <TextScheduleValueFooter>
            Sau 30-60 ngày kể từ khi ghi nhận thành công
          </TextScheduleValueFooter>
        )}
      </ViewScheduleDetailFooter>
    </ViewScheduleDetail>
  );
};

export default Status;
