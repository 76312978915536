import styled from "styled-components";
import { colors } from "theme/index";
import { ViewCommon, TextCommon } from "components/index";

export const Container = styled(ViewCommon)`
  justify-content: center;
  align-items: center;
`;

export const Text = styled(TextCommon)`
  color: ${colors.Black};
  font-size: 19px;
  font-weight: 700;
  font-family: "AvertaStdCY-Bold" !important;
  text-align-last: center;
  position: absolute;
  top: 230px;
  left: 60px;
`;

export const Img = styled.img`
  width: 63px;
  height: 65px;
  position: absolute;
  top: 146px;
  left: 146px;
`;

export const ViewLoading = styled.div`
  width: 100vw;
  height: 100vh;
  display: inline-block;
  overflow: hidden;
  /* background: red; */
`;

export const CircleProcess = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
  position: fixed; /* or absolute */
  top: 25%;
  left: 5%;

  div {
    box-sizing: content-box;
    position: absolute;
    border-width: 80px;
    border-style: solid;
    opacity: 1;
    border-radius: 50%;
    animation: process 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  @keyframes process {
    0% {
      top: 100px;
      left: 100px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: -100px;
      left: -100px;
      width: 400px;
      height: 400px;
      opacity: 0;
    }
  }

  div:nth-child(1) {
    border-color: #dfdff5;
  }

  div:nth-child(2) {
    border-color: #eaeaf9;
    animation-delay: -0.5s;
  }
`;

export const CircleProcessBig = styled.div`
  background: #eaeaf9;
  justify-content: center;
  align-items: center;
`;

export const CircleProcessSmall = styled.div`
  background: #dfdff5;
`;
