import React from "react";
import {
  View,
} from "./index.style";

import { withRouter } from "react-router-dom";
import * as ROUTER from "constants/routes";
import OrderItem from "../OrderItem/index";

const OrderListWaiting = ({ history }) => {
  const onClickOrderDetail = () => {
    history.push({ pathname: ROUTER.ORDER_DETAIL, type: DATA.type });
  };

  return (
    <View id="list_order">
      <OrderItem data={DATA} onClick={onClickOrderDetail} />
      <OrderItem data={DATA} onClick={onClickOrderDetail} />
      <OrderItem data={DATA} onClick={onClickOrderDetail} />
      <OrderItem data={DATA} onClick={onClickOrderDetail} />
      <OrderItem data={DATA} onClick={onClickOrderDetail} />
    </View>
  );
};

export default withRouter(OrderListWaiting);

const DATA = {
  id: 1,
  type: 'waiting'
};
