import { Header } from "components/index";
import React, { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import BoxItem from "./components/BoxItem/index";
import Footer from "./components/Footer/index";
import { Container, TextTitle } from "./index.style";
import * as ROUTER from "constants/routes";

const DealsNoteContainer = ({ history }) => {
  const onClick = () => {
    history.push(ROUTER.NOTE_PREORDER);
  };
  return (
    <>
      <Header title="Ưu đãi từ thương hiệu - Shopee" />
      <Container maxWidth="sm">
        <TextTitle>
          Bạn sẽ được hoàn tiền nhiều hơn, khi đọc kỹ các thông tin dưới đây
        </TextTitle>
        <BoxItem />
        <Footer onClick={onClick} />
      </Container>
    </>
  );
};

export default withRouter(DealsNoteContainer);
