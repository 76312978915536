import styled from "styled-components";
import { colors } from "theme/index";
import { ViewCommon, TextCommon } from "components/index";

export const View = styled(ViewCommon)`
  background-color: ${colors.White};
`;

export const ViewItem = styled(ViewCommon)`
  height: 100px;
  background: ${colors.White};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ViewHeader = styled(ViewCommon)`
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const ViewCenter = styled(ViewCommon)`
  flex: 1;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  border-bottom: 1px solid #f4f3f4;
`;

export const ViewFooter = styled(ViewCommon)`
  justify-content: center;
  height: 100%;
  border-bottom: 1px solid #f4f3f4;
`;

export const Img = styled.img`
  height: 33px;
  width: 80px;
  margin: 0px 20px 0px 20px;
  object-fit: scale-down;
`;

export const ImgIcon = styled.img`
  margin-right: 20px;
  height: 32px;
  width: 32px;
`;

export const TextValue = styled(TextCommon)`
  color: ${colors.Black};
  font-size: 12px;
  font-weight: 600;
`;
export const TextCashback = styled(TextCommon)`
  color: ${(props) => {
    if (props.type === "waiting") {
      return colors.Orange;
    } else if (props.type === "done") {
      return colors.Primary;
    } else {
      return colors.Red;
    }
  }};
  font-size: 12px;
  font-weight: 600;
`;
