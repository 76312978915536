import images from "assets/images";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getFakeListBrand } from "services/auth.service";
import {
  ViewItem,
  View,
  Img,
  TextTitle,
  Text,
  ViewFooter,
  ViewDays,
  TextDay,
  ViewHeader,
  ImgAll,
} from "./index.style";
import * as ROUTER from "constants/routes";

const ListDeals = (props) => {
  const history = useHistory();
  const onClickDetail = () => {
    history.push(ROUTER.DEALS_NOTE);
  };

  React.useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    getFakeListBrand({ page: 1 })
      .then((res) => {
        if (res.status === "success") {
          props.setState([...props.state, ...res.data.campaigns]);
        }
      })
      .catch((err) => {
        console.log("err: " + err);
      });
  };

  const _renderDays = () => (
    <ViewDays>
      <TextDay>Còn 42 ngày</TextDay>
    </ViewDays>
  );

  const _renderBrand = () => <Img src={images.icShopee} />;

  const _renderPercent = (id) => <Text>{id}%</Text>;

  const _renderItem = (data, i) => (
    <ViewItem onClick={onClickDetail} key={i}>
      <ViewHeader>
        {_renderDays()}
        {_renderBrand()}
      </ViewHeader>
      <ViewFooter>
        <TextTitle>Hoàn tiền đến</TextTitle>
        {_renderPercent(data.id)}
      </ViewFooter>
    </ViewItem>
  );

  return (
    <View id="list_deals">
      {props.state.map((data, i) => _renderItem(data, i))}
      <ViewItem className="center mr-10">
        <ImgAll src={images.icAll} />
        <TextTitle>Xem tất cả</TextTitle>
      </ViewItem>
    </View>
  );
};

export default ListDeals;
