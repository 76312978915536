import styled from "styled-components";
import { spacing, colors, metric } from "theme/index";
import { TextCommon, ViewCommon } from "components/index";
import { ButtonCommon } from "components/index";

export const Container = styled(ViewCommon)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  height: ${metric.heightNavigationBottom};
  background: white;
  box-shadow: 10px 0px 10px 0px rgba(33, 33, 33, 0.1);
`;

export const Tab = styled(ViewCommon)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.active ? colors.BgPrimary : colors.Tr};
  border-radius: 8px;
  width: 72px;
  height: 53px;
`;
export const ImgTab = styled.img`
  width: 24px;
  height: 24px;
`;

export const TitleTab = styled(TextCommon)`
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  margin-top: 5px;
  /* identical to box height */

  text-align: center;

  /* MB Bank */
  color: ${(props) => props.active ? colors.Primary : colors.Black};
`;

export const BtnSubmit = styled(ButtonCommon)``;
