import React from "react";
import { TextTitle, TextValue, View } from "./index.style";

const Row = ({ title, value }) => {
  return (
    <View>
      <TextTitle>{title}</TextTitle>
      <TextValue>{value}</TextValue>
    </View>
  );
};

export default Row;