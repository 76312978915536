import styled from "styled-components";
import {
  ViewCommon,
  TextCommon,
  CheckBoxCommon,
  ButtonCommon,
} from "components/index";
import { colors } from "theme/index";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import WarningIcon from '@material-ui/icons/Warning';

export const Container = styled(ViewCommon)`
  padding: 20px;
  align-items: center;
`;

export const View = styled(ViewCommon)`
  margin-top: 160px;
`;

export const TextLabel = styled(TextCommon)`
  font-size: 19px;
  color: ${colors.Black};
  font-family: "AvertaStdCY-Bold" !important;
  font-weight: bold;
  text-align-last: center;
  margin-bottom: 8px;
`;

export const Text = styled(TextCommon)`
  font-size: 13px;
  color: ${colors.Black};
  opacity: 0.5;
  font-weight: 600;
  text-align-last: center;
`;

export const CheckBox = styled(CheckBoxCommon)`
  margin-right: 10px;
`;

export const Button = styled(ButtonCommon)`
  position: fixed;
  bottom: 0;
  width: 90%;
  margin-bottom: 20px;
`;

export const IconSuccess = styled(CheckCircleRoundedIcon)`
  color: #27ae60;
  height: 64px;
  width: 64px;
  align-self: center;
  margin-bottom: 20px;
`;

export const IconWarning = styled(WarningIcon)`
  color: #EFD358;
  height: 64px;
  width: 64px;
  align-self: center;
  margin-bottom: 20px;
`;
