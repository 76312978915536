import React, { useState, useRef } from "react";
import { Container, Button, Text } from "./index.style";

const Withdrawal = ({ onClick }) => {
  const [active, setActive] = useState(true);
  return (
    <Container>
      <Button onClick={onClick} disabled={!active}>
        Rút tiền
      </Button>
      {!active && (
        <Text>
          Bạn cần tối thiểu 50.000đ trong số dư <br />
          hoàn tiền để có thể yêu cầu rút tiền.
        </Text>
      )}
    </Container>
  );
};

export default Withdrawal;
