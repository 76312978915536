import images from "assets/images";
import { Header } from "components/index";
import React, { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { Container, Button, Text } from "./index.style";

const Footer = ({ onClick }) => {
  return (
    <Container>
      <Button onClick={onClick}>Tôi đã hiểu</Button>
      <Text>Đọc kỹ quy định hoàn tiền trước khi mua hàng nhé!</Text>
    </Container>
  );
};

export default Footer;
