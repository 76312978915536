import styled from "styled-components";
import { colors } from "theme/index";
import { ViewCommon, TextCommon, ButtonCommon } from "components/index";

export const Container = styled(ViewCommon)`
  justify-content: center;
  align-items: center;
  background: "#E5E5E5";
`;

export const View = styled(ViewCommon)``;

export const Img = styled.img`
  width: 301px;
  height: 261px;
  margin: 37px;
`;

export const TextWarning = styled(TextCommon)`
  color: ${colors.Black};
  font-size: 19px;
  font-weight: bold;
  font-family: "AvertaStdCY-Bold" !important;
`;

export const TexBottom = styled(TextCommon)`
  color: ${colors.Grey};
  font-size: 13px;
  margin-bottom: 12px;
  align-self: center;
`;

export const ButtonBottom = styled(ButtonCommon)`
  margin: 18px 18px 10px 18px;
`;
export const RectangularBottom = styled(ViewCommon)`
  background: ${colors.White};
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;
