export const HOME = '/';
export const ORDERS = '/orders';
export const WITHDRAW = '/withdraw';
export const SUPPORT = '/support';
export const SUPPORT_FAQs = '/support/detail';
export const BRANDS_DETAIL = '/brands-detail';
export const NOTE_PREORDER = "/note-preorder";
export const NOTE_PREORDER_PROCESS = "/note-preorder-process";
export const SEARCH = "/search";
export const DEALS_NOTE = "/deals-note";
export const WITHDRAW_REQUIRED = "/withdraw-required";
export const WITHDRAW_CONFIRM = "/withdraw-confirm";
export const WITHDRAW_COMPLETED = "/withdraw-completed";
export const ORDER_DETAIL = "/order-detail";
export const DEALS_LIST = "/deals-list";
