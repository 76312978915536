import images from "assets/images";
import React, { useState, useRef } from "react";
import { colors } from "theme/index";
import {
  Text,
  ViewBox,
  View,
  Img,
  TextTitle,
  BtnShowAll,
  HeaderBox,
  TitleBtnShowAll,
} from "./index.style";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ListDeals from "../ListDeals/index";

const DealsBrands = ({ onClick }) => {
  const [state, setState] = useState([]);

  return (
    <>
      <TextTitle>Ưu đãi từ thương hiệu</TextTitle>
      <ViewBox>
        <HeaderBox>
          <Img src={images.icShopee} />
          <BtnShowAll onClick={onClick}>
            <TitleBtnShowAll>Xem tất cả</TitleBtnShowAll>
            <ArrowForwardIosIcon
              style={{ color: colors.BlueBlack, fontSize: 16 }}
            />
          </BtnShowAll>
        </HeaderBox>
        <ListDeals state={state} setState={setState} />
      </ViewBox>
    </>
  );
};

export default DealsBrands;
