
const getColors = () => {
  const profile = localStorage.getItem("primary_key");
  return profile ? profile : "#EB2D4B";
}

const colors = {
  Primary: "#141ed2",
  BgPrimary: "#141ed21A",
  Secondary: "#2AD2C9",
  Blue: "#3980e4",
  DarkBlue: "#3d75c4",
  White: "#fff",
  Black: "#2B2E2C",
  Grey: "#64737D",
  LightGrey: "#ddd",
  LighterGrey: "#d6d6d6",
  Yellow: "#f7b500",
  Red: "#EB2D4B",
  Purple: "#5f259f",
  Green: "#2ad1c8",
  Shadow: "#223746B3",
  Transparent: "#ffffff00",
  GreyProcess: "#E0E0E0",
  GreyProcessMore: "#EBEBEB",
  GreyBorder: "#F4F3F4",
  Orange: "#F2994A",
  BlueBlack: "#00007D",
};

export default colors;

// 100%	FF	255
// 99%	FC	252
// 98%	FA	250
// 97%	F7	247
// 96%	F5	245
// 95%	F2	242
// 94%	F0	240
// 93%	ED	237
// 92%	EB	235
// 91%	E8	232
// 90%	E6	230
// 89%	E3	227
// 88%	E0	224
// 87%	DE	222
// 86%	DB	219
// 85%	D9	217
// 84%	D6	214
// 83%	D4	212
// 82%	D1	209
// 81%	CF	207
// 80%	CC	204
// 79%	C9	201
// 78%	C7	199
// 77%	C4	196
// 76%	C2	194
// 75%	BF	191
// 74%	BD	189
// 73%	BA	186
// 72%	B8	184
// 71%	B5	181
// 70%	B3	179
// 69%	B0	176
// 68%	AD	173
// 67%	AB	171
// 66%	A8	168
// 65%	A6	166
// 64%	A3	163
// 63%	A1	161
// 62%	9E	158
// 61%	9C	156
// 60%	99	153
// 59%	96	150
// 58%	94	148
// 57%	91	145
// 56%	8F	143
// 55%	8C	140
// 54%	8A	138
// 53%	87	135
// 52%	85	133
// 51%	82	130
// 50%	80	128
// 49%	7D	125
// 48%	7A	122
// 47%	78	120
// 46%	75	117
// 45%	73	115
// 44%	70	112
// 43%	6E	110
// 42%	6B	107
// 41%	69	105
// 40%	66	102
// 39%	63	99
// 38%	61	97
// 37%	5E	94
// 36%	5C	92
// 35%	59	89
// 34%	57	87
// 33%	54	84
// 32%	52	82
// 31%	4F	79
// 30%	4D	77
// 29%	4A	74
// 28%	47	71
// 27%	45	69
// 26%	42	66
// 25%	40	64
// 24%	3D	61
// 23%	3B	59
// 22%	38	56
// 21%	36	54
// 20%	33	51
// 19%	30	48
// 18%	2E	46
// 17%	2B	43
// 16%	29	41
// 15%	26	38
// 14%	24	36
// 13%	21	33
// 12%	1F	31
// 11%	1C	28
// 10%	1A	26
// 9%	17	23
// 8%	14	20
// 7%	12	18
// 6%	0F	15
// 5%	0D	13
// 4%	0A	10
// 3%	08	8
// 2%	05	5
// 1%	03	3
// 0%	00	0
