import images from "assets/images";
import React from "react";
import {
  ViewItem,
  View,
  Img,
  TextValue,
  TextCashback,
  ViewCenter,
  ViewFooter,
  ImgIcon,
  ViewHeader,
} from "./index.style";
import * as ROUTER from "constants/routes";

const OrderItem = ({ data, onClick }) => {
  const onClickItem = () => {
    onClick && onClick(data.id);
  };

  const _renderTextCashBack = () => {
    if (data.type === "waiting") {
      return (
        <TextCashback type={data.type}>Hoàn tiền dự kiến: 8.800đ</TextCashback>
      );
    } else if (data.type === "done") {
      return <TextCashback type={data.type}>Hoàn tiền: 8.800đ</TextCashback>;
    } else {
      return <TextCashback type={data.type}>Đơn hàng đã hủy</TextCashback>;
    }
  };

  return (
    <ViewItem key={data.id} onClick={onClickItem}>
      <ViewHeader>
        <Img src={images.icShopee} />
      </ViewHeader>
      <ViewCenter>
        <TextValue>Giá trị: 880.000 đ</TextValue>
        <TextValue>Ngày mua: 05/12/2019</TextValue>
        {_renderTextCashBack()}
      </ViewCenter>
      <ViewFooter>
        <ImgIcon src={images.icArrowRight} />
      </ViewFooter>
    </ViewItem>
  );
};

export default OrderItem;
