import { ViewCommon, TextCommon } from "components/index";
import styled from "styled-components";
import { colors } from "theme/index";

export const View = styled(ViewCommon)`
  height: 64px;
  background: ${colors.White};
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 20px;
`;

export const Button = styled(ViewCommon)`
  height: 48px;
  width: 48px;
  justify-content: center;
  align-items: center;
`;

export const BackButton = styled(ViewCommon)`
  height: 64px;
  background: ${colors.White};
  justify-content: space-between;
  align-items: center;
`;

export const Text = styled(TextCommon)`
  color: ${colors.Black};
  font-weight: bold;
  font-size: 17px;
  align-self: flex-end;
  display: block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-family: "AvertaStdCY-Bold" !important;
`;

export const Img = styled.img`
  width: 24px;
  height: 24px;
  align-self: flex-end;
`;
