import styled from "styled-components";
import { colors, spacing } from "theme";

const Styles = styled.button`
  display: flex;
  background: ${(props) => (props.disabled ? '#21212126' : colors.Primary)};
  height: 60px;
  width: auto;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 17px;
  color: ${colors.White};
  outline: none;
  border: none;
  font-weight: bold;
  font-family: "AvertaStdCY-Bold" !important;
`;

export default Styles;
