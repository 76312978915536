import React, { useState, useRef } from "react";

import { NavigationBottom } from "components/index";
import ListQuestion from "./components/ListQuestion/index"
import { Container } from "./index.style";
import { withRouter } from "react-router-dom";

const SupportContainer = () => {
  const [value, setValue] = useState(0);
  return (
    <>
      <Container maxWidth="sm"> 
        <ListQuestion/>
        <NavigationBottom />
      </Container>
    </>
  );
};

export default withRouter(SupportContainer);
