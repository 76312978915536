import { Header, NavigationBottom } from "components/index";
import React, { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import {
  Container,
  ViewHorizontal,
  ViewBox,
  ViewTitleBox,
  TextTitleBox,
  TextBox,
  TextTitle,
  ViewSearch,
  TextSearch,
  ImgSearch,
} from "./index.style";
import * as ROUTER from "constants/routes";
import images from "assets/images";
import List from "./components/List/index";

const DealsListContainer = ({ history }) => {
  const onClickSearch = () => {
    history.push(ROUTER.SEARCH);
  };

  const onClickItem = () => {
    history.push(ROUTER.DEALS_NOTE);
  };
  return (
    <>
      <Header title={"Ưu đãi từ thương hiệu - Shopee"} />
      <Container maxWidth="sm">
        <ViewHorizontal>
          <ViewBox className="left">
            <ViewTitleBox>
              <TextTitleBox>Hoàn tiền không hạn mức</TextTitleBox>
            </ViewTitleBox>
            <TextBox>
              <TextBox className="bold">Không hạng mức</TextBox> hoàn tiền đối
              đa cho các đơn hàng thuộc chương trình
            </TextBox>
          </ViewBox>
          <ViewBox className="right">
            <ViewTitleBox>
              <TextTitleBox>Liên tục đổi mới hàng tuần</TextTitleBox>
            </ViewTitleBox>
            <TextBox>
              <TextBox className="bold">Không phân biệt</TextBox> tiền thưởng
              cho khách hàng mua lần đầu và lần sau
            </TextBox>
          </ViewBox>
        </ViewHorizontal>
        <TextTitle>Thương hiệu</TextTitle>
        <ViewSearch onClick={onClickSearch}>
          <ImgSearch src={images.icSearch} />
          <TextSearch>Tìm kiếm thương hiệu</TextSearch>
        </ViewSearch>
        <List onClickItem={onClickItem} />
        <NavigationBottom />
      </Container>
    </>
  );
};

export default withRouter(DealsListContainer);
