import React from "react";
import { Container, Text, View, Input, ViewInput } from "./index.style";

const InputMoney = ({ onChangeMoney }) => {
  const onChangeValue = (value) => {
    onChangeMoney && onChangeMoney(value);
  };
  return (
    <Container maxWidth="sm">
      <View>
        <Text style={{ marginBottom: "8px" }}>Nhập số tiền cần rút</Text>
        <Input type="tel" onChangeValue={onChangeValue}></Input>
      </View>
      <View>
        <Text style={{ marginBottom: "8px" }}>Tối thiểu 70.000đ</Text>
        <Text style={{ fontSize: "19px", alignSelf: "flex-end" }}>VNĐ</Text>
      </View>
    </Container>
  );
};

export default InputMoney;
