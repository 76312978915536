import iconHomeActive from "./images/navigation/home.png";
import iconHome from "./images/navigation/home_not.png";
import iconOrdersActive from "./images/navigation/orders.png";
import iconOrders from "./images/navigation/orders_not.png";
import iconWithdrawActive from "./images/navigation/withdraw.png";
import iconWithdraw from "./images/navigation/withdraw_not.png";
import iconSupportActive from "./images/navigation/support.png";
import iconSupport from "./images/navigation/support_not.png";
import icDollar from "./images/home/dollar.png";
import icWaitDollar from "./images/home/wait_dollar.png";
import icHome from "./images/common/home.png";
import icSearchEmpty from "./images/home/ic_search_empty.png";
import icShopee from "./images/common/shopee.png";
import icArrowRight from "./images/common/arrow_right.png";
import icNotePreOrder from "./images/note_preorder/ic_note_pre_order.png";
import icNotePreOrder_Process from "./images/note_preorder/ic_note_pre_process.png";
import icSearch from "./images/common/search.png";
import icInfo from "./images/common/info.png";

import iconSupportHeadPhone from "./images/support/ic_headphone.png";
import iconSupportPhone from "./images/support/ic_support_phone.png";
import iconSupportQuestion from "./images/support/ic_support_question.png";
import iconSupportCallcenter from "./images/support/ic_callcenter.png";
import iconSupportPhoneDetail from "./images/support/ic_phone.png";

import icDate from "./images/home/ic_date.png";
import icTime from "./images/home/ic_time.png";
import icWarning from "./images/home/ic_warning.png";
import icOrderSchedule  from "./images/order/ic_order_schedule.png";
import icOrderChecked from "./images/order/ic_order_checked.png";
import icOrderCheck from "./images/order/ic_order_check.png";

import icEmptyHistory from "./images/withdraw/ic_empty_history.png";
import icDollarBlue from "./images/withdraw/dollar_blue.png";

import icAll from "./images/home/ic_all.png";

const images = {
  iconHomeActive,
  iconHome,
  iconOrdersActive,
  iconOrders,
  iconWithdrawActive,
  iconWithdraw,
  iconSupportActive,
  iconSupport,
  icDollar,
  icWaitDollar,
  icShopee,
  icArrowRight,
  icSearchEmpty,
  icInfo,
  //Order
  icNotePreOrder,
  icNotePreOrder_Process,
  icSearch,
  icHome,
  icOrderSchedule,
  icOrderChecked,
  icOrderCheck,
  //Support
  iconSupportHeadPhone,
  iconSupportPhone,
  iconSupportQuestion,
  iconSupportCallcenter,
  iconSupportPhoneDetail,
  icDate,
  icTime,
  icWarning,
  //withdraw
  icEmptyHistory,
  icDollarBlue,
  icAll
};

export default images;
