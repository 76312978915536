import React, { useState, useRef } from "react";
import {
  View,
  ViewItem,
  Img,
  ViewHeader,
  ViewFooter,
  TextTitle,
  ImgIcon,
  Text,
  TextRemainingDay,
} from "./index.style";
import images from "assets/images";

const List = ({ onClickItem }) => {
  const _renderItem = (data, i) => (
    <ViewItem key={i} onClick={onClickItem}>
      <ViewHeader>
        <Img src={images.icShopee} />
      </ViewHeader>
      <ViewFooter>
        <TextTitle>Hoàn tiền</TextTitle>
        <Text>11.1%</Text>
        <TextRemainingDay>Còn 1 ngày</TextRemainingDay>
      </ViewFooter>
      <ImgIcon src={images.icArrowRight} />
    </ViewItem>
  );

  return (
    <View id="list">
      {_renderItem()}
      {_renderItem()}
    </View>
  );
};

export default List;
