import React from "react";
import { View, Text, Img, Button } from "./index.style";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import images from "assets/images";
import { colors } from "theme/index";
import { useHistory } from "react-router-dom";
import * as ROUTER from "constants/routes";

const Header = (props) => {
  const history = useHistory();

  const onBack = () => {
    history.goBack();
  };

  const onBackHome = () => {
    history.push(ROUTER.HOME);
  };

  return (
    <View>
      <Button onClick={onBack}>
        <ArrowBackIosIcon
          style={{
            color: colors.Primary,
            alignSelf: "flex-start",
            fontSize: 28,
          }}
        />
      </Button>
      <Text>{props.title}</Text>
      <Button onClick={onBackHome}>
        <Img src={images.icHome} />
      </Button>
    </View>
  );
};

export default Header;
