import styled from "styled-components";
import { colors } from "theme/index";
import { ViewCommon, TextCommon, InputCommon } from "components/index";

export const Container = styled(ViewCommon)`
  background: "#E5E5E5";
`;

export const View = styled(ViewCommon)``;

export const BoxSearch = styled(ViewCommon)`
  background: ${colors.White};
  /* width: 100%; */
  height: 64px;
  align-items: center;
  flex-direction: row;
  border-top: 1px solid #f2f1f2;
`;

export const TextSearch = styled(InputCommon)`
  width: 80%;
  margin-left: 10px;
  border-color: ${colors.White};

  input {
    background: ${colors.White};
    border-color: ${colors.White};
    font-size: 15px;
  }
  input:focus {
    outline: none;
  }
`;

export const Text = styled(TextCommon)`
  color: ${colors.Black};
  font-size: 19px;
  font-weight: bold;
  font-family: "AvertaStdCY-Bold" !important;
`;

export const ImgSearch = styled.img`
  width: 20px;
  height: 20px;
  margin: 20px 0px 20px 20px;
`;

export const BoxSearchEmpty = styled(ViewCommon)`
  justify-content: center;
  align-items: center;
  margin-top: 100px;
`;
export const ImgSearchEmpty = styled.img`
  width: 64px;
  height: 64px;
`;
export const TextSearchEmpty = styled(TextCommon)`
  color: ${colors.Black};
  font-size: 19px;
  font-weight: bold;
  font-family: "AvertaStdCY-Bold" !important;
`;
export const BoxSearchResult = styled(ViewCommon)`
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  margin-top: 70px;
`;
