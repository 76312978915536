import styled from "styled-components";
import { spacing, colors } from "theme/index";
import { ViewCommon, TextCommon } from "components/index";

export const View = styled(ViewCommon)`
  flex-direction: row;
  padding-left: 10px;
  margin-top: 10px;
  overflow: auto;
  white-space: nowrap;
  ::-webkit-scrollbar {
    display: none;
  }
  height: 148px;
`;

export const ViewFooter = styled(ViewCommon)`
  align-items: center;
  justify-content: flex-end;
  height: 100%;
`;

export const ViewHeader = styled(ViewCommon)`
  width: 100%;
`;

export const ViewItem = styled(ViewCommon)`
  min-width: 120px;
  height: 136px;
  background: ${colors.White};
  border-radius: 10px;
  margin: 6px;
  box-shadow: 0px 0px 4px 4px rgba(99, 99, 99, 0.08);
  align-items: center;
  padding: 6px;

  &.center {
    justify-content: center;
  }
  &.mr-10 {
    margin-right: 10px;
  }
`;

export const Img = styled.img`
  height: 40px;
  width: 70px;
  object-fit: scale-down;
  align-self: center;
`;

export const ImgAll = styled.img`
  height: 24px;
  width: 24px;
  object-fit: scale-down;
  align-self: center;
  margin-bottom: 16px;
`;

export const Text = styled(TextCommon)`
  color: ${colors.Black};
  font-size: 16px;
  font-weight: bold;
  font-family: "AvertaStdCY-Bold" !important;
`;

export const ViewDays = styled(ViewCommon)`
  flex: 1;
  align-items: flex-end;
  margin-bottom: 4px;
  width: 100%;
`;

export const TextDay = styled(TextCommon)`
  color: ${colors.White};
  font-size: 9px;
  border-radius: 10px;
  background: ${colors.Black};
  padding: 1px 5px 1px 5px;
`;

export const TextTitle = styled(TextCommon)`
  color: ${colors.Grey};
  font-size: 13px;
`;
