import React, { useState, useRef } from "react";
import { Container } from "./index.style";
import {
  BoxSearch,
  ImgSearch,
  TextSearch,
  BoxSearchEmpty,
  ImgSearchEmpty,
  TextSearchEmpty,
  BoxSearchResult,
} from "./index.style";
import images from "assets/images";
import ListBrands from "../home/components/ListBrands/index";
import { withRouter } from "react-router-dom";
import { Header } from "components/index";

const SearchContainer = () => {
  return (
    <Container maxWidth="sm">
      <Header title="" />
      <BoxSearch>
        <ImgSearch src={images.icSearch}></ImgSearch>
        <TextSearch
          placeholder="Tìm kiếm thương hiệu"
          // value= {this.state.query}
          // onchange = {this.handleInputChange}
        ></TextSearch>
      </BoxSearch>

      <BoxSearchEmpty>
        <ImgSearchEmpty src={images.icSearchEmpty} />
        <TextSearchEmpty>Không có kết quả</TextSearchEmpty>
        <TextSearchEmpty>tìm kiếm phù hợp</TextSearchEmpty>
      </BoxSearchEmpty>
      {/* 
          <BoxSearchResult>
          <ListBrands />
          </BoxSearchResult>   */}
    </Container>
  );
};

export default withRouter(SearchContainer);
