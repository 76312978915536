import styled from "styled-components";
import { spacing, colors } from "theme/index";
import { ViewCommon, TextCommon, ButtonCommon } from "components/index";

export const Container = styled(ViewCommon)``;

export const View = styled(ViewCommon)`
  position: relative;
`;

export const IndicatorLeft = styled(ViewCommon)`
  height: 4px;
  background: ${colors.Primary};
  margin-top: 6px;
  width: 0px;

  ${(props) => {
    if (props.active === null) {
      return `
      -webkit-transition: width 1s ease;
      -moz-transition: width 1s ease;
      -o-transition: width 1s ease;
      transition: width 1s ease;
      width: 100%;
      `;
    } else if (props.active === true) {
      return `
      -webkit-animation: slide 0.5s forwards ;
      animation: slide 0.5s forwards ;
      position: absolute;
      left: 150px;
      width: 100%;
      @-webkit-keyframes slide {
        100% {
          left: 0;
        }
      }
      @keyframes slide {
        100% {
          left: 0;
        }
      }
      `;
    }
  }}
`;

export const IndicatorRight = styled(ViewCommon)`
  height: 4px;
  background: ${colors.Primary};
  margin-top: 6px;
  -webkit-animation: slide 0.5s forwards;
  animation: slide 0.5s forwards;
  position: absolute;
  left: -100px;
  width: 100%;
  @-webkit-keyframes slide {
    100% {
      left: 0;
    }
  }

  @keyframes slide {
    100% {
      left: 0;
    }
  }
`;

export const ViewHorizontal = styled(ViewCommon)`
  flex-direction: row;
`;

export const Tab = styled(ViewCommon)`
  margin: 0px 0px 0px 20px;
`;

export const Img = styled.img`
  width: 72px;
`;

export const Text = styled(TextCommon)`
  color: ${(props) =>
    props.active === null || props.active ? colors.Primary : colors.Black};
  font-size: 17px;
  font-weight: bold;
  font-family: "AvertaStdCY-Bold" !important;
`;

export const TextLarge = styled(TextCommon)`
  color: ${colors.Black};
  font-size: 19px;
  font-weight: bold;
  font-family: "AvertaStdCY-Bold" !important;
`;
