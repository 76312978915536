import styled from "styled-components";
import { spacing, colors } from "theme/index";
import { ViewCommon, TextCommon } from "components/index";

export const View = styled(ViewCommon)``;

export const CurrentBalance = styled(ViewCommon)`
  background: ${colors.Primary};
  height: 83px;
  flex-direction: row;
  justify-content: left;
  align-items: center;
`;

export const WaitingBalance = styled(ViewCommon)`
  background: ${colors.White};
  height: 83px;
  flex-direction: row;
  justify-content: left;
  align-items: center;
`;

export const Img = styled.img`
  width: 24px;
  height: 24px;
  margin: 20px;
`;

export const Text = styled(TextCommon)`
  color: ${colors.White};
  font-size: 13px;
  font-weight: 600;
`;

export const TextLarge = styled(TextCommon)`
  color: ${colors.White};
  font-size: 19px;
  font-weight: bold;
  font-family: "AvertaStdCY-Bold" !important;
`;
