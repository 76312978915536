import { Header } from "components/index";
import React, { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import {
  IconSuccess,
  IconWarning,
  Container,
  TextLabel,
  View,
  Button,
  Text,
} from "./index.style";
import * as ROUTER from "constants/routes";

const WithdrawCompletedContainer = ({ history, location }) => {
  const onClick = () => {
    history.push(ROUTER.HOME);
  };
  return (
    <Container maxWidth="sm">
      <View>
        <IconSuccess />
        {/* <IconWarning /> */}
        <TextLabel>
          Yêu cầu rút tiền <br /> của bạn đã được ghi nhận
        </TextLabel>
        <Text>
          Tiền sẽ được chuyển về tài khoản sau khi <br /> hoàn tất xử lý.
        </Text>
      </View>
      <Button onClick={onClick}>Hoàn tất</Button>
    </Container>
  );
};

export default withRouter(WithdrawCompletedContainer);
