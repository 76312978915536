/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { getFakeListBrand } from "services/auth.service";
import useInitData from "./useInitData";

const useListBrands = () => {
  const { isLoading, listBrands } = useInitData();
  const [listItems, setListItems] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [page, setPage] = useState(1);

  const root = document.getElementById("root");

  const handleScroll = (isMounted) => {
    const innerHeight = window.innerHeight; // static height browser
    let scrollTop = root.scrollTop; // dynamic position scroll
    const viewList = document.getElementById("list_brands");
    if (!viewList) return;
    const clientHeight = viewList.clientHeight; // static height list
    const offsetTop = viewList.offsetTop; // static height from position list to top
    if (innerHeight + scrollTop >= clientHeight + offsetTop || !isFetching) {
      if (isMounted) {
        setIsFetching(true);
      }
    }
  };

  useEffect(() => {
    setListItems(listBrands);
  }, [listBrands]);

  useEffect(() => {
    root.addEventListener("scroll", () => {
      handleScroll(true);
    });
    return () => {
      root.removeEventListener("scroll", () => {
        handleScroll(false);
      });
    };
  }, []);

  useEffect(() => {
    if (!isFetching) return;
    let nextPage = page + 1;
    fetchMoreListItems(nextPage);
  }, [isFetching]);

  const fetchMoreListItems = (nextPage) => {
    getFakeListBrand({ page: nextPage })
      .then((res) => {
        if (res.status === "success") {
          setListItems((prevState) => [...prevState, ...res.data.campaigns]);
          setIsFetching(false);
          setPage(nextPage);
        }
      })
      .catch((err) => {
        console.log("err: " + err);
      });
  };

  return [isLoading, isFetching, listItems];
};

export default useListBrands;
