import styled from "styled-components";
import { spacing, colors } from "theme/index";
import { ViewCommon, TextCommon } from "components/index";

export const View = styled(ViewCommon)`
  height: 36px;
  border-radius: 10px;
  margin: 0px 20px 0px 20px;
  background: ${colors.White};
  justify-content: center;
  align-items: center;
  flex-direction: row
`;

export const Text = styled(TextCommon)`
  color: ${colors.Grey};
  font-size: 12px;
  margin-left: 8px
`;

export const TextTitle = styled(TextCommon)`
  color: ${colors.Grey};
  font-size: 18px;
  font-weight: bold;
  font-family: "AvertaStdCY-Bold" !important;
  margin: 20px
`;

export const Img = styled.img`
  height: 16px;
  object-fit: scale-down;
`;

