import styled from "styled-components";
import { ViewCommon, TextCommon } from "components/index";
import { colors } from "theme/index";

export const Container = styled(ViewCommon)`
  border-radius: 10px;
  background: ${colors.White};
  padding: 20px 20px 18px 20px;
  margin-bottom: 20px
`;

export const View = styled(ViewCommon)``;

export const Text = styled(TextCommon)`
  font-size: 13px;
  font-weight: bold;
  color: ${colors.Black};
`;

export const TextBold = styled(Text)`
  font-size: 19px;
  font-family: "AvertaStdCY-Bold" !important;
  margin-top: 4px;
`;
