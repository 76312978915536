import React, { useState, useRef } from "react";
import {
  Container,
  View,
  Text,
  TextLabel,
  ViewFooter,
  TextPercent,
} from "./index.style";

const Categories = () => {
  const _renderItem = (obj, i) => (
    <View key={i}>
      <Text>{obj.name}</Text>
      <ViewFooter>
        <TextLabel>Hoàn tiền</TextLabel>
        <TextPercent>{obj.percent}%</TextPercent>
      </ViewFooter>
    </View>
  );
  return <Container>{DATA.map((obj, i) => _renderItem(obj, i))}</Container>;
};

export default Categories;

const DATA = [
  {
    name: "Nhà cửa, đời sống",
    percent: 3.2,
  },
  {
    name: "Thời trang",
    percent: 1.2,
  },
  {
    name: "Mẹ và bé",
    percent: 2.8,
  },
  {
    name: "Đồ công nghệ",
    percent: 4.3,
  },
  {
    name: "Điện gia dụng",
    percent: 3.2,
  },
  {
    name: "Phụ kiện thời trang",
    percent: 3.2,
  },
  {
    name: "Mẹ và bé",
    percent: 2.8,
  },
  {
    name: "Đồ công nghệ",
    percent: 4.3,
  },
  {
    name: "Điện gia dụng",
    percent: 3.2,
  },
];
