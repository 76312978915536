import styled from "styled-components";
import { ViewCommon, TextCommon, InputCommon } from "components/index";
import { colors } from "theme/index";

export const Container = styled(ViewCommon)`
  border-radius: 10px;
  background: ${colors.White};
  flex-direction: row;
  align-items: center;
  border: 1px solid ${colors.Primary};
  margin-top: 20px;
  box-shadow: 0px 0px 4px 4px rgba(99, 99, 99, 0.08);
  justify-content: space-between;
  padding: 20px;
`;

export const View = styled(ViewCommon)``;

export const Text = styled(TextCommon)`
  font-weight: 600;
  font-size: 13px;
  color: ${colors.Black};
`;

export const Input = styled(InputCommon)`
  margin: 0px;
  border-color: ${colors.White};
  input {
    background: ${colors.White};
    border-color: ${colors.White};
    font-size: 22px;
    font-family: "AvertaStdCY-Bold" !important;
    height: auto;
    width: 130px;
    padding: 0px;
  }
  input:focus {
    outline: none;
  }
`;
