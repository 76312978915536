import styled from "styled-components";
import { spacing, colors } from "theme/index";
import { ViewCommon, TextCommon, ButtonCommon } from "components/index";

export const Container = styled(ViewCommon)`
  
`;

export const View = styled(ViewCommon)``;

export const Text = styled(TextCommon)`
  color: ${colors.Red};
  font-size: 15px;
  font-weight: bold;
  align-self: center;
  margin-bottom: 12px;
  font-family: "AvertaStdCY-Bold" !important;
  align-self: center
`;

export const Button = styled(ButtonCommon)`
  margin: 20px 15px 10px 15px;
`
