import styled from "styled-components";
import { spacing, colors } from "theme/index";
import { ViewCommon, TextCommon, ButtonCommon } from "components/index";

export const Container = styled(ViewCommon)`
  padding: 20px;
`;

export const ViewHorizontal = styled(ViewCommon)`
  flex-direction: row;
`;

export const ViewBox = styled(ViewCommon)`
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  flex: 1;
  &.left {
    margin-right: 5px;
  }

  &.right {
    margin-left: 5px;
  }
`;

export const ViewTitleBox = styled(TextCommon)`
  background: ${colors.BlueBlack};
  border-radius: 20px 20px 0px 0px;
  padding: 20px;
`;

export const TextTitleBox = styled(TextCommon)`
  font-weight: bold;
  font-size: 21px;
  color: #ffffff;
  font-family: "AvertaStdCY-Bold";
`;

export const TextBox = styled(TextCommon)`
  font-size: 15px;
  color: ${colors.Grey};
  margin: 20px;
  &.bold {
    font-weight: bold;
    margin: 0px;
  }
`;

export const TextTitle = styled(TextCommon)`
  font-weight: bold;
  font-family: "AvertaStdCY-Bold";
  font-size: 24px;
  color: ${colors.Grey};
  margin: 20px 0px 20px 0px;

`;

export const ViewSearch = styled(ViewCommon)`
  height: 36px;
  border-radius: 10px;
  margin: 0px;
  background: ${colors.White};
  justify-content: center;
  align-items: center;
  flex-direction: row
`;

export const TextSearch = styled(TextCommon)`
  color: ${colors.Grey};
  font-size: 12px;
  margin-left: 8px
`;

export const ImgSearch = styled.img`
  height: 16px;
  object-fit: scale-down;
`;

