import { ViewCommon, TextCommon } from "components/index";
import styled from "styled-components";
import { colors } from "theme/index";

export const Container = styled(ViewCommon)`
  padding: 20px;
`;

export const View = styled(ViewCommon)``;

export const TextTitle = styled(TextCommon)`
  font-size: 24px;
  color: ${colors.BlueBlack};
  font-weight: bold;
  font-family: "AvertaStdCY-Bold" !important;
`;
