import images from "assets/images";
import React, { useState, useRef } from "react";
import { colors } from "theme/index";
import {
  Container,
  ViewHeader,
  Img,
  TextTitle,
  Text,
  TextMore,
  TextBlue,
  View,
} from "./index.style";

const BoxItem = () => {
  const [moreBox1, setMoreBox1] = useState(false);
  const [moreBox2, setMoreBox2] = useState(false);
  const onClickMoreBox1 = () => {
    setMoreBox1(true);
  };

  const onClickMoreBox2 = () => {
    setMoreBox2(true);
  };

  return (
    <Container maxWidth="sm">
      <View>
        <ViewHeader>
          <Img src={images.icInfo} />
          <TextTitle>Ưu đãi thương hiệu là gì?</TextTitle>
        </ViewHeader>
        {!moreBox1 ? (
          <Text>
            Là chương trình Mbbank kết hợp cùng các thương hiệu lớn như Lazada,
            Shopee để tăng mức % hoàn tiền lên gấp 2 thậm chí gấp 3 và không
            giới hạn mức hoàn tiền tối đa cho một đơn hàng...{" "}
            <TextMore onClick={onClickMoreBox1}>Xem thêm</TextMore>
          </Text>
        ) : (
          <Text>
            Là chương trình Mbbank kết hợp cùng các thương hiệu lớn như Lazada,
            Shopee{" "}
            <TextBlue>
              để tăng mức % hoàn tiền lên gấp 2 thậm chí gấp 3 và không giới hạn
              mức hoàn tiền tối đa cho một đơn hàng.
            </TextBlue>{" "}
            <br />
            <br />
            VD: Khi mua một đơn hàng trong ƯU ĐÃI TỪ THƯƠNG HIỆU trị giá
            2,000,00đ với mức hoàn tiền là 20%, bạn sẽ nhận được hoàn tiền lên
            đến 400,000đ
            <br />
            <br />
            ƯU ĐÃI TỪ THƯƠNG HIỆU sẽ vẫn được chạy song song với chương trình
            hoàn tiền truyền thống.
            <br />
            <br />
            <TextTitle
              style={{
                fontSize: "14px",
                color: colors.Grey,
              }}
            >
              Cách mua sắm với ƯU ĐÃI TỪ THƯƠNG HIỆU
            </TextTitle>
            <br />
            1. Chọn thương hiệu yêu thích trong danh sách các nhãn hàng có trong
            mục ƯU ĐÃI TỪ THƯƠNG HIỆU
            <br />
            2. Bạn sẽ được chuyển sang trang web bán hàng của đối tác.
            <TextTitle
              style={{
                fontSize: "14px",
                color: colors.Grey,
                fontStyle: "italic",
              }}
            >
              {" "}
              Ghi nhớ: Bạn chỉ được nhận hoàn tiền thưởng thêm từ ƯU ĐÃI TỪ
              THƯƠNG HIỆU khi bạn mua các sản phẩm trên trang bạn được chuyển
              qua.
            </TextTitle>
            <br />
            <br />
            - Chương trình ưu đãi từ thương hiệu là chương trình tách biệt với
            chương trình hoàn tiền truyền thống.
            <br />
            <br />- Bạn có thể kéo xuống phần "Thương hiệu mua sắm” ở trang chủ
            để mua sắm và nhận hoàn tiền như trước.
          </Text>
        )}
      </View>
      <View>
        <ViewHeader>
          <Img src={images.icInfo} />
          <TextTitle>Ưu đãi cực khủng</TextTitle>
        </ViewHeader>
        {!moreBox2 ? (
          <Text>
            1. Mức hoàn tiền cực khủng Bạn có thể được nhận hoàn tiền lên đến
            20% đến 30% khi mua sắm các nhãn hàng từ NGÀY VÀNG.
            <br />
            2. Vô vàn thương hiệu uy tín...{" "}
            <TextMore onClick={onClickMoreBox2}>Xem thêm</TextMore>
          </Text>
        ) : (
          <Text>
            <TextTitle
              style={{
                fontSize: "14px",
                color: colors.Grey,
              }}
            >
              1. Mức hoàn tiền cực khủng
            </TextTitle>
            <br />
            Bạn có thể được nhận hoàn tiền{" "}
            <TextBlue>
              lên đến 20% đến 30% khi mua sắm các nhãn hàng từ ƯU ĐÃI TỪ THƯƠNG
              HIỆU.
            </TextBlue>
            <br />
            <br />
            <TextTitle
              style={{
                fontSize: "14px",
                color: colors.Grey,
              }}
            >
              2. Vô vàn thương hiệu uy tín
            </TextTitle>
            <br />
            Bạn có thể thoả sức chọn các thương hiệu uy tín phù hợp với nhu cầu
            mua sắm của bạn để được nhận hoàn tiền
            <br />
            <br />
            <TextTitle
              style={{
                fontSize: "14px",
                color: colors.Grey,
              }}
            >
              3. Được cập nhật và đổi mới hàng tuần
            </TextTitle>
            <br />
            Các thương hiệu có mức hoàn tiền khủng từ chương trình sẽ luôn được
            đổi mới và gia tăng số lượng mỗi ngày.
            <br />
            <br />
            <TextTitle
              style={{
                fontSize: "14px",
                color: colors.Grey,
              }}
            >
              Lưu ý
            </TextTitle>
            <br />
            <br />- Chương trình ưu đãi từ thương hiệu là chương trình tách biệt
            với chương trình hoàn tiền truyền thống.
            <br />
            <br />- Bạn có thể kéo xuống phần "Thương hiệu mua sắm” ở trang chủ
            để mua sắm và nhận hoàn tiền như trước.
          </Text>
        )}
      </View>
    </Container>
  );
};

export default BoxItem;
