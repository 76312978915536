import React from "react";
import { Text, TextBold } from "./index.style";

const AccountInfo = () => {
  return (
    <Text>
      Số tiền sẽ được rút về tài khoảng MB Bank số{" "}
      <TextBold>28379292382939</TextBold>
    </Text>
  );
};

export default AccountInfo;
