import React from "react";
import { Container, ImgTab, Tab, TitleTab } from "./index.style";
import images from "assets/images";
import { useRouteMatch, useHistory } from "react-router-dom";
import * as ROUTES from "constants/routes";

const NavigationBottom = () => {
  let { path, url } = useRouteMatch();
  const history = useHistory();
  const isActiveTab = (route) => path === route;

  const onClickTabHome = () => {
    history.replace(ROUTES.HOME);
  };

  const onClickTabOrders = () => {
    history.replace(ROUTES.ORDERS);
  };

  const onClickTabWithdraw = () => {
    history.replace(ROUTES.WITHDRAW);
  };

  const onClickTabSupport = () => {
    history.replace(ROUTES.SUPPORT);
  };

  const isActiveHomeTab = () =>
    isActiveTab(ROUTES.HOME) || isActiveTab(ROUTES.DEALS_LIST);

  return (
    <Container>
      <Tab active={isActiveHomeTab()} onClick={onClickTabHome}>
        <ImgTab
          src={isActiveHomeTab() ? images.iconHomeActive : images.iconHome}
        ></ImgTab>
        <TitleTab active={isActiveHomeTab()}>Mua sắm</TitleTab>
      </Tab>

      <Tab active={isActiveTab(ROUTES.ORDERS)} onClick={onClickTabOrders}>
        <ImgTab
          src={
            isActiveTab(ROUTES.ORDERS)
              ? images.iconOrdersActive
              : images.iconOrders
          }
        ></ImgTab>
        <TitleTab active={isActiveTab(ROUTES.ORDERS)}>Đơn hàng</TitleTab>
      </Tab>

      <Tab active={isActiveTab(ROUTES.WITHDRAW)} onClick={onClickTabWithdraw}>
        <ImgTab
          src={
            isActiveTab(ROUTES.WITHDRAW)
              ? images.iconWithdrawActive
              : images.iconWithdraw
          }
        ></ImgTab>
        <TitleTab active={isActiveTab(ROUTES.WITHDRAW)}>Rút tiền</TitleTab>
      </Tab>

      <Tab active={isActiveTab(ROUTES.SUPPORT)} onClick={onClickTabSupport}>
        <ImgTab
          src={
            isActiveTab(ROUTES.SUPPORT)
              ? images.iconSupportActive
              : images.iconSupport
          }
        ></ImgTab>
        <TitleTab active={isActiveTab(ROUTES.SUPPORT)}>Hỗ trợ</TitleTab>
      </Tab>
    </Container>
  );
};

export default NavigationBottom;
