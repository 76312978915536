import { ViewCommon } from "components/index";
import styled from "styled-components";
import { colors } from "theme/index";

export const ViewCallPhone1 = styled(ViewCommon)`
  min-width: 60px;
  min-height: 60px;
  margin: ${(props) => props.margin + "px"};
  background: ${colors.Primary};
  border-radius: 50px;
`;
export const ViewCallPhone2 = styled(ViewCommon)`
  min-width: 90px;
  min-height: 90px;
  margin-bottom: ${(props) => props.margin + "px"};
  margin-right: 20px;
  position: fixed;
  bottom: 0;
  right: 0;
  background: ${colors.BgPrimary};
  border-radius: 50px;
  justify-content: center;
  align-items: center;
`;

export const Img = styled.img`
  min-width: 22px;
  min-height: 22px;
  margin: 25px;
 
`;
