import React, { useState } from "react";
import { Container, AntTab, AntTabs } from "./index.style";
import { NavigationBottom } from "components/index";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import OrderListWaiting from "./components/OrderListWaiting/index";
import OrderListCashBack from "./components/OrderListCashBack/index";
import OrderListCancel from "./components/OrderListCancel/index";

const OrdersContainer = () => {
  const [value, setValue] = useState(0);

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const _renderPage = () => {
    if (value === 0) {
      return <OrderListWaiting />;
    }
    if (value === 1) {
      return <OrderListCashBack />;
    }
    if (value === 2) {
      return <OrderListCancel />;
    }
  };
  return (
    <>
      <Container maxWidth="sm">
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <AntTabs
              variant="fullWidth"
              value={value}
              onChange={handleChange}
              aria-label="nav tabs example"
            >
              <LinkTab label="Chờ hoàn tiền" />
              <LinkTab label="Đã hoàn tiền" href="/trash" />
              <LinkTab label="Đã hủy" href="/spam" />
            </AntTabs>
          </AppBar>

          {_renderPage()}
        </div>

        <NavigationBottom />
      </Container>
    </>
  );
};

function LinkTab(props) {
  return (
    <AntTab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "white",
  },
}));

export default withRouter(OrdersContainer);
