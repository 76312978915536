import React from "react";
import { Container, Button } from "./index.style";

const Footer = ({ onClick }) => {
  return (
    <Container>
      <Button onClick={onClick}>Rút tiền</Button>
    </Container>
  );
};

export default Footer;
