/* eslint-disable react-hooks/exhaustive-deps */
import images from "assets/images";
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  ViewItem,
  View,
  Img,
  TextTitle,
  Text,
  ViewFooter,
  ImgIcon,
  ViewHeader,
  ViewProgress,
} from "./index.style";
import { useListBrands } from "hooks/home/index";
import * as ROUTER from "constants/routes";
import { useHistory } from "react-router-dom";

const ListBrands = () => {
  const [isLoading, isFetching, listItems] = useListBrands();
  const history = useHistory();

  const onClickBrand = () => {
    history.push(ROUTER.BRANDS_DETAIL, { listItems });
  };

  const _renderBrand = () => <Img src={images.icShopee} />;
  const _renderPercent = (id) => <Text>{id}%</Text>;
  const _renderItem = (data, i) => (
    <ViewItem key={i} onClick={onClickBrand}>
      <ViewHeader>{_renderBrand()}</ViewHeader>
      <ViewFooter>
        <TextTitle>Hoàn tiền đến</TextTitle>
        {_renderPercent(data.id)}
      </ViewFooter>
      <ImgIcon src={images.icArrowRight} />
    </ViewItem>
  );

  return (
    <View id="list_brands">
      {listItems.map((data, i) => _renderItem(data, i))}
      {(isLoading || isFetching) && (
        <ViewProgress>
          <CircularProgress size={24} />
        </ViewProgress>
      )}
    </View>
  );
};

export default ListBrands;
