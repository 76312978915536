import React from "react";
import { ButtonBottom, Container, Img, TextWarning } from "./index.style";
import { TexBottom, RectangularBottom } from "./index.style";
import images from "assets/images";
import { withRouter } from "react-router-dom";
import * as ROUTES from "constants/routes";
import { Header } from "components/index";

const NotePreOrder = ({ history }) => {
  const onClickProcess = () => {
    history.push(ROUTES.NOTE_PREORDER_PROCESS);
  };
  return (
    <>
      <Header title="Mua sắm" />
      <Container maxWidth="sm">
        <Img src={images.icNotePreOrder}></Img>

        <TextWarning>Lưu ý:</TextWarning>
        <TextWarning>Không thêm sản phẩm </TextWarning>
        <TextWarning>vào giỏ hàng từ trước</TextWarning>

        <RectangularBottom>
          <ButtonBottom onClick={onClickProcess}>Tôi đã hiểu</ButtonBottom>
          <TexBottom>
            Đọc kỹ quy định hoàn tiền trước khi mua hàng nhé!
          </TexBottom>
        </RectangularBottom>
      </Container>
    </>
  );
};

export default withRouter(NotePreOrder);
