import { ViewCommon, TextCommon, ImageCommon } from "components/index";
import styled from "styled-components";
import { colors } from "theme/index";

export const Container = styled(ViewCommon)`
  margin-bottom: 116px
`;

export const View = styled(ViewCommon)`
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  margin-top: 20px;
`;

export const ViewHeader = styled(ViewCommon)`
  background: #717791;
  height: 66px;
  border-radius: 20px 20px 0px 0px;
  flex-direction: row;
  padding: 20px;
`;

export const Img = styled(ImageCommon)`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

export const TextTitle = styled(TextCommon)`
  font-size: 19px;
  color: ${colors.White};
  font-weight: 700;
  font-family: "AvertaStdCY-Bold" !important;
`;

export const Text = styled(TextCommon)`
  font-size: 15px;
  color: #717791;
  margin: 20px;
`;

export const TextMore = styled(TextCommon)`
  font-size: 15px;
  color: ${colors.Primary};
`;

export const TextBlue = styled(TextCommon)`
  font-size: 15px;
  color: ${colors.Primary};
  font-weight: 700;
`;

