import images from "assets/images";
import { Header } from "components/index";
import React, { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import Categories from "../Categories/index";
import Provisions from "../Provisions/index";
import {
  Container,
  Tab,
  Text,
  ViewHorizontal,
  View,
  IndicatorLeft,
  IndicatorRight,
} from "./index.style";

const Tabs = ({ history }) => {
  const [isTabLeftActive, setTabLeftActive] = useState(null);

  const onClickTabLeft = () => {
    setTabLeftActive(true);
  };

  const onClickTabRight = () => {
    setTabLeftActive(false);
  };

  return (
    <>
      <Container maxWidth="sm">
        <ViewHorizontal>
          <Tab onClick={onClickTabLeft}>
            <Text active={isTabLeftActive}>Danh mục</Text>
            <View>
              <IndicatorLeft active={isTabLeftActive} />
            </View>
          </Tab>
          <Tab onClick={onClickTabRight}>
            <Text active={isTabLeftActive === false}>Quy định hoàn tiền</Text>
            <View>{isTabLeftActive === false && <IndicatorRight />}</View>
          </Tab>
        </ViewHorizontal>
        {isTabLeftActive === true || isTabLeftActive === null ? (
          <Categories />
        ) : (
          <Provisions />
        )}
      </Container>
    </>
  );
};

export default withRouter(Tabs);
