import styled from "styled-components";
import { spacing, colors } from "theme/index";
import { ViewCommon, TextCommon, ImageCommon } from "components/index";

export const Container = styled(ViewCommon)``;

export const View = styled(ViewCommon)`
  background: ${colors.White};
  padding: 20px;
  border-bottom: 1px solid ${colors.GreyBorder};
`;

export const ViewEmpty = styled(ViewCommon)`
  justify-content: center;
  align-items: center;
`;

export const TextEmpty = styled(TextCommon)`
  color: ${colors.Black};
  font-size: 13px;
  font-weight: 600;
  margin-top: 20px;
  opacity: 0.5;
  text-align-last: center;
`;

export const TextTitle = styled(TextCommon)`
  color: ${colors.Black};
  font-size: 18px;
  font-weight: 700;
  margin: 10px 20px 22px 20px;
  font-family: "AvertaStdCY-Bold" !important;
`;

export const Text = styled(TextCommon)`
  color: ${colors.Black};
  font-size: 13px;
  font-weight: bold;
  font-family: "AvertaStdCY-Bold" !important;
  margin-bottom: 4px;
`;

export const Img = styled(ImageCommon)`
  width: 153.42px;
  height: 144.63px;
`;
