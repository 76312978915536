import images from "assets/images";
import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { colors } from "theme/index";
import {
  Container,
  CurrentBalance,
  WaitingBalance,
  Text,
  View,
  Img,
  TextLarge,
} from "./index.style";
import * as ROUTER from "constants/routes";
import { StoreContext } from "utils/store";

const CashBack = () => {
  const history = useHistory();
  const onClickView = () => {
    history.push(ROUTER.ORDERS);
  };

  const context = React.useContext(StoreContext);
  const [theme, setTheme] = context.theme;

  return (
    <Container onClick={onClickView}>
      <CurrentBalance theme={theme}>
        <Img src={images.icDollar} />
        <View>
          <Text>Hoàn tiền hiện có</Text>
          <TextLarge>80,000đ</TextLarge>
        </View>
      </CurrentBalance>
      <WaitingBalance>
        <Img src={images.icWaitDollar} />
        <View>
          <Text style={{ color: colors.Primary }}>Hoàn tiền chờ duyệt</Text>
          <TextLarge style={{ color: colors.Primary }}>80,000đ</TextLarge>
        </View>
      </WaitingBalance>
    </Container>
  );
};

export default CashBack;
