import React, { useState, useRef } from "react";
import { NavigationBottom } from "components/index";
import { withRouter } from "react-router-dom";
import CashBack from "./components/Cashback/index";
import { Container } from "./index.style";
import Withdrawal from "./components/Withdrawal/index";
import History from "./components/History/index";
import * as ROUTER from "constants/routes";

const WithdrawContainer = ({ history }) => {
  const onClick = () => {
    history.push(ROUTER.WITHDRAW_REQUIRED);
  };
  return (
    <Container maxWidth="sm">
      <CashBack />
      <Withdrawal onClick={onClick} />
      <History />
      <NavigationBottom />
    </Container>
  );
};

export default withRouter(WithdrawContainer);
