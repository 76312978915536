import images from "assets/images";
import React, { useState, useRef } from "react";
import { colors } from "theme/index";
import {
  Container,
  TextTitle,
  View,
  Text,
  ViewEmpty,
  Img,
  TextEmpty,
} from "./index.style";

const History = ({}) => {
  const _renderStatus = (status) => {
    switch (status) {
      case "success":
        return <Text style={{ color: colors.Primary }}>Thành công</Text>;
      case "cancel":
        return <Text style={{ color: colors.Red }}>Bị từ chối</Text>;
      case "waiting":
        return <Text style={{ color: colors.Yellow }}>Đang xử lý</Text>;
      default:
        break;
    }
  };
  const _renderItem = (obj, i) => (
    <View key={i}>
      <Text>{obj.date}</Text>
      <Text>Số tiền rút: {obj.money}đ</Text>
      <Text>Rút tiền về tài khoản: {obj.account}</Text>
      {_renderStatus(obj.status)}
    </View>
  );

  const _renderEmpty = (obj, i) => (
    <ViewEmpty>
      <Img src={images.icEmptyHistory} />
      <TextEmpty>
        Lịch sử rút tiền của bạn sẽ xuất hiện <br />ở đây sau khi thực hiện
      </TextEmpty>
    </ViewEmpty>
  );

  return (
    <Container>
      <TextTitle>Lịch sử rút tiền</TextTitle>
      {DATA.length > 0
        ? DATA.map((obj, i) => _renderItem(obj, i))
        : _renderEmpty()}
    </Container>
  );
};

export default History;

const DATA = [
  {
    date: "30/11/2019",
    money: "296.000",
    account: "28379292382939",
    status: "success",
  },
  {
    date: "30/11/2019",
    money: "296.000",
    account: "28379292382939",
    status: "cancel",
  },
  {
    date: "30/11/2019",
    money: "296.000",
    account: "28379292382939",
    status: "waiting",
  },
];
