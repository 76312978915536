import styled from "styled-components";
import { ViewCommon, TextCommon } from "components/index";

export const Container = styled(ViewCommon)`
  padding: 20px;
`;

export const View = styled(ViewCommon)``;

export const Text = styled(TextCommon)``;
