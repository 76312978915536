import React, { useState, useRef } from "react";
import { Container, Text, TextBold } from "./index.style";

const Form = (props) => {
  return (
    <Container maxWidth="sm">
      <Text>{props.title}</Text>
      <TextBold>{props.value}</TextBold>
    </Container>
  );
};

export default Form;
