import styled from "styled-components";
import { colors } from "theme/index";
import { ViewCommon, TextCommon } from "components/index";

export const View = styled(ViewCommon)`
  
`;

export const TextValue = styled(TextCommon)`
  color: ${colors.Black};
  font-size: 18px;
  margin: 0px 0px 0px 20px;
  font-weight: 700;
  font-family: "AvertaStdCY-Bold";
`;

export const TextTitle = styled(TextCommon)`
  color: ${colors.Black};
  font-size: 14px;
  margin: 20px 0px 0px 20px;
  font-weight: 700;
  font-family: "AvertaStdCY-Bold";
  opacity: 0.5;
`;