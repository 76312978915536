import styled from "styled-components";
import { colors } from "theme/index";
import { ViewCommon, TextCommon } from "components/index";

export const ViewScheduleDetail = styled(ViewCommon)`
  flex-direction: row;
  background: ${colors.White};
`;
export const ViewScheduleDetailHeader = styled(ViewCommon)`
  background: ${colors.White};
  width: 16px;
  align-items: center;
  flex-direction: column;
  margin-left: 20px;
`;

export const LineStraight = styled.img`
  height: 35px;
  width: 2px;
  background-color: ${colors.Orange};
`;

export const ViewScheduleDetailFooter = styled(ViewCommon)`
  background: ${colors.White};
  align-items: left;
  flex-direction: column;
  margin-left: 27px;
`;
export const TextScheduleTitleFooter = styled(TextCommon)`
  color: ${colors.Black};
  font-size: 14px;
  margin: 0px 0px 5px 0px;
  height: 17px;
  font-weight: 700;
  font-family: "AvertaStdCY-Bold";
`;
export const TextScheduleValueFooter = styled(TextCommon)`
  color: ${colors.Black};
  font-size: 14px;
  margin: 0px 0px 10px 0px;
  height: 17px;
  font-weight: 600;
  opacity: 0.5;
`;
