import styled from "styled-components";
import { spacing, colors } from "theme/index";
import { ViewCommon, TextCommon, ButtonCommon } from "components/index";

export const Container = styled(ViewCommon)`
  background: ${colors.White};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const View = styled(ViewCommon)``;

export const Text = styled(TextCommon)`
  color: ${colors.Black};
  font-size: 13px;
  font-weight: 600;
  opacity: 0.6;
  align-self: center;
  margin-bottom: 12px;
`;

export const Button = styled(ButtonCommon)`
  margin: 18px 15px 10px 15px;
`;

export const TextLabel = styled(TextCommon)`
  color: ${colors.Black};
  font-size: 12px;
  font-weight: 600;
  margin-top: 14px;
`;

export const TextPercent = styled(TextCommon)`
  color: ${colors.Black};
  font-size: 18px;
  font-weight: bold;
  font-family: "AvertaStdCY-Bold" !important;
`;
