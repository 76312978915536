import styled from "styled-components";
import { spacing, colors } from "theme/index";
import { ViewCommon, TextCommon } from "components/index";

export const ViewBox = styled(ViewCommon)`
  background: ${colors.White};
  box-shadow: 0px 8px 8px 0 rgba(33, 33, 33, 0.1);
  padding-top: 20px;
  padding-bottom: 16px;
`;

export const View = styled(ViewCommon)``;

export const HeaderBox = styled(ViewCommon)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0px 20px;
`;

export const Img = styled.img`
  height: 32px;
  object-fit: scale-down;
`;

export const Text = styled(TextCommon)`
  color: ${colors.White};
  font-size: 13px;
  font-weight: 600;
`;

export const TextTitle = styled(TextCommon)`
  color: ${colors.Grey};
  font-size: 18px;
  font-weight: bold;
  font-family: "AvertaStdCY-Bold" !important;
  margin: 20px;
`;

export const BtnShowAll = styled(ViewCommon)`
  flex-direction: row;
  align-items: center;
`;
export const ImgArrow = styled.img`
  width: 6px;
  height: 12px;
  margin-right: 6px;
  filter: ${colors.Primary};
  -webkit-filter: invert(100%);
`;

export const TitleBtnShowAll = styled(TextCommon)`
  font-size: 13px;
  font-weight: 700;
  font-family: "AvertaStdCY-Bold" !important;
  color: ${colors.BlueBlack};
`;
