import styled from "styled-components";
import { spacing, colors } from "theme/index";
import { ViewCommon, TextCommon, ButtonCommon } from "components/index";

export const Container = styled(ViewCommon)``;

export const View = styled(ViewCommon)``;

export const ViewHorizontal = styled(ViewCommon)`
  flex-direction: row;
  align-items: center;
`;

export const ViewImgBrand = styled(ViewCommon)`
  height: 100px;
  width: 100px;
  background: ${colors.White};
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin: 20px;
`;

export const Img = styled.img`
  width: 72px;
`;

export const Text = styled(TextCommon)`
  color: ${colors.Primary};
  font-size: 13px;
  font-weight: 600;
`;

export const TextLarge = styled(TextCommon)`
  color: ${colors.Black};
  font-size: 19px;
  font-weight: bold;
  font-family: "AvertaStdCY-Bold" !important;
`;
