import images from "assets/images";
import { Header } from "components/index";
import React, { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import Footer from "./components/Footer/index";
import Tabs from "./components/Tabs/index";
import {
  Container,
  ViewHorizontal,
  ViewImgBrand,
  Img,
  View,
  TextLarge,
  Text,
} from "./index.style";
import * as ROUTER from "constants/routes";

const BrandsDetailContainer = ({ history }) => {
  const onClickBuy = () => {
    history.push(ROUTER.NOTE_PREORDER);
  };
  return (
    <>
      <Container maxWidth="sm">
        <Header title={"Mua sắm"} />
        <ViewHorizontal>
          <ViewImgBrand>
            <Img src={images.icShopee} />
          </ViewImgBrand>
          <View>
            <TextLarge>SHOPEE</TextLarge>
            <Text>Hoàn tiền đến 4.30%</Text>
          </View>
        </ViewHorizontal>
        <Tabs />
        <Footer onClick={onClickBuy} />
      </Container>
    </>
  );
};

export default withRouter(BrandsDetailContainer);
