/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { getFakeListBrand } from "services/auth.service";
import { StoreContext } from "utils/store";

const useInitData = () => {
  const [isLoading, setLoading] = useState(true);
  const [listBrands, setListBrands] = useState([]);
  const context = useContext(StoreContext);
  const [theme, setTheme] = context.theme;

  useEffect(() => {
    fetchListBrands(1);
  }, []);

  const fetchListBrands = (page) => {
    getFakeListBrand({ page })
      .then((res) => {
        if (res.status === "success") {
          setListBrands(res.data.campaigns);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err: " + err);
      });
  };

  return { isLoading, listBrands };
};

export default useInitData;
