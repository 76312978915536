import images from "assets/images";
import React, { useState, useRef } from "react";
import { colors } from "theme/index";
import {
  CurrentBalance,
  WaitingBalance,
  Text,
  View,
  Img,
  TextLarge,
} from "./index.style";

const CashBack = () => {
  const [value, setValue] = useState(0);
  return (
    <>
      <CurrentBalance>
        <Img src={images.icDollar} />
        <View>
          <Text>Hoàn tiền hiện có</Text>
          <TextLarge>80,000đ</TextLarge>
        </View>
      </CurrentBalance>
      <WaitingBalance>
        <Img src={images.icWaitDollar} />
        <View>
          <Text style={{ color: colors.Primary }}>Hoàn tiền chờ duyệt</Text>
          <TextLarge style={{ color: colors.Primary }}>80,000đ</TextLarge>
        </View>
      </WaitingBalance>
    </>
  );
};

export default CashBack;
