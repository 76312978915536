import images from "assets/images";
import { Header } from "components/index";
import React, { useState, useRef } from "react";
import { Container, Img, Text, View } from "./index.style";

const TotalMoney = () => {
  return (
    <Container maxWidth="sm">
      <Img src={images.icDollarBlue} />
      <View>
        <Text>Tổng tiền có thể rút</Text>
        <Text style={{ fontSize: "19px" }}>80,000đ</Text>
      </View>
    </Container>
  );
};

export default TotalMoney;
