import styled from "styled-components";
import { spacing, colors } from "theme/index";
import { ViewCommon, TextCommon } from "components/index";

export const Container = styled(ViewCommon)`
  padding: 20px;
`;

export const View = styled(ViewCommon)``;

const Row = styled(ViewCommon)`
  height: 72px;
  background: ${colors.White};
  align-items: center;
  flex-direction: row;
  padding: 22px;
`;

export const Row1 = styled(Row)`
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid #f2f1f2;
`;

export const Row2 = styled(Row)`
  border-bottom: 1px solid #f2f1f2;
`;

export const Row3 = styled(Row)`
  border-radius: 0px 0px 10px 10px;
`;

export const Text = styled(TextCommon)`
  color: ${colors.Black};
  font-size: 12px;
  font-weight: 600;
`;

export const TextTitle = styled(TextCommon)`
  color: ${colors.Black};
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 8px;
`;

export const Img = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 24px;
`;
