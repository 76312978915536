import { ViewCommon, ModalCommon } from "components/index";
import styled from "styled-components";

export const Container = styled(ViewCommon)`
  overflow-x: hidden;
  overflow-y: hidden;
`;

export const View = styled(ViewCommon)``;

export const Modal = styled(ModalCommon)``;

export const ViewLoading = styled(ViewCommon)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #eaeaf5;
`;
