import React from "react";
import {
  Container,
  CircleProcessBig,
  CircleProcessSmall,
  ViewLoading,
  CircleProcess,
} from "./index.style";
import { Img, Text } from "./index.style";
import images from "assets/images";
import { withRouter } from "react-router-dom";
import { Header } from "components/index";

const NotePreOrderProcessContainer = () => {
  const [seconds, setSeconds] = React.useState(5);
  React.useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds(0);
    }
  });

  return (
    <>
      <Header title="Mua sắm" />
      <Container maxWidth="sm">
        <ViewLoading>
          <CircleProcess>
            <CircleProcessBig />
            <CircleProcessSmall />
            <Img src={images.icNotePreOrder_Process}></Img>
            <Text>
              Bạn sẽ được chuyển tới Tiki
              <br /> trong {seconds} giây nữa...
            </Text>
          </CircleProcess>
        </ViewLoading>
      </Container>
    </>
  );
};

export default withRouter(NotePreOrderProcessContainer);
