import styled from "styled-components";
import { ViewCommon, ImageCommon, TextCommon } from "components/index";
import { colors } from "theme/index";

export const Container = styled(ViewCommon)`
  border-radius: 10px;
  background: ${colors.White};
  flex-direction: row;
  align-items: center;
`;

export const View = styled(ViewCommon)`
  margin: 20px 0px 20px 0px;
`;

export const Img = styled(ImageCommon)`
  height: 24px;
  width: 24px;
  margin: 0px 20px 0 20px;
`;

export const Text = styled(TextCommon)`
  font-weight: 600;
  font-size: 13px;
  color: ${colors.Black};
`;
