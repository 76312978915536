import images from "assets/images";
import React from "react";
import {
  ViewItem,
  View,
  Img,
  TextValue,
  TextCashback,
  ViewCenter,
  ViewFooter,
  ImgIcon,
  ViewHeader,
} from "./index.style";
import * as ROUTER from "constants/routes";
import { useHistory } from "react-router-dom";
import OrderItem from "../OrderItem/index";

const OrderListCancel = () => {
  const history = useHistory();
  const onClickOrderDetail = () => {
    history.push(ROUTER.ORDER_DETAIL);
  };

  return (
    <View id="list_order">
      <OrderItem data={DATA} onClick={onClickOrderDetail} />
      <OrderItem data={DATA} onClick={onClickOrderDetail} />
      <OrderItem data={DATA} onClick={onClickOrderDetail} />
      <OrderItem data={DATA} onClick={onClickOrderDetail} />
    </View>
  );
};

export default OrderListCancel;

const DATA = {
  id: 1,
};
