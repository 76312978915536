import images from "assets/images";
import { Header } from "components/index";
import React, { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import {
  Container,
  TextTitle,
  Img,
  Row1,
  Row2,
  Row3,
  Text,
} from "./index.style";

const Provisions = ({ history }) => {
  return (
    <Container>
      <TextTitle>Thời gian hoàn tiền</TextTitle>
      <Row1>
        <Img src={images.icTime} />
        <Text>Đơn hàng được ghi nhận sau 24h</Text>
      </Row1>
      <Row2>
        <Img src={images.icDate} />
        <Text>Bạn sẽ nhận hoàn tiền vào ngày 25 của tháng tiếp theo</Text>
      </Row2>
      <Row3>
        <Img src={images.icWarning} />
        <Text>
          Hoàn tiền được áp dụng trên số tiền cuối cùng sau khi đã trừ đi khuyến
          mãi, giảm giá và chi phí vận chuyển.
        </Text>
      </Row3>
    </Container>
  );
};

export default withRouter(Provisions);
