import React from "react";
import { NavigationBottom } from "components/index";
import CashBack from "./components/CashBack/index";
import { Container, View, Modal, ViewLoading } from "./index.style";
import DealsBrands from "./components/DealsBrands/index";
import { getToken, setToken } from "helpers/auth.helper";
import ListBrands from "./components/ListBrands/index";
import Search from "./components/Search/index";
import { withRouter } from "react-router-dom";
import * as ROUTER from "constants/routes";
import BlockLoading from "components/BlockLoading/index";

const HomeContainer = ({ history }) => {
  // const [isLoading] = useInitData();

  let token = getToken();
  if (token == null) {
    setToken(
      "eyJpdiI6IkhTYXorWUhJbjZIdko5bkFUTjluaXc9PSIsInZhbHVlIjoiY3VBUEs5QlQwMXdIQkVVQkc3OUxwQXlxaVwvaXl1TmVHb1p5eklvakJTUnNGNmxoV3YySEhWZDR6RTh3OWtRKytGXC8zVHVzdnFEa08xNzdDWG5XWVYxQT09IiwibWFjIjoiYTUyOTAzYjZkZWJiNzQzM2UxYWIxNTAyNzUwODIyMmYxM2E0NWYzYmNjZDVmZGFjYTU4OTA5OTBjMmJmMmNjYSJ9"
    );
  }

  // const profile = localStorage.getItem("primary_key");
  // if(profile == null) {
  //   localStorage.setItem("primary_key", "#f7b500");
  // }

  const onClickSearch = () => {
    history.push(ROUTER.SEARCH);
  };

  const onClickShowAll = () => {
    history.push(ROUTER.DEALS_LIST);
  };

  return (
    <Container maxWidth="sm">
      <View>
        <CashBack />
        <DealsBrands onClick={onClickShowAll} />
        <Search onClickSearch={onClickSearch} />
        <ListBrands />
      </View>
      <NavigationBottom />
      {/* <Modal open>
        <ViewLoading>
          <BlockLoading />
        </ViewLoading>
      </Modal> */}
    </Container>
  );
};

export default withRouter(HomeContainer);
