import styled from "styled-components";
import { spacing, colors, metric } from "theme/index";
import { ViewCommon, TextCommon } from "components/index";

export const View = styled(ViewCommon)`
  margin-bottom: calc(${metric.heightNavigationBottom} + ${metric.margin20});
  flex-direction: column;
  overflow: auto;
  white-space: nowrap;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ViewFooter = styled(ViewCommon)`
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  padding-right: 16px;
`;

export const ViewHeader = styled(ViewCommon)`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const ViewItem = styled(ViewCommon)`
  height: 100px;
  background: ${colors.White};
  border-radius: 10px;
  margin: 20px 20px 0px 20px;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Img = styled.img`
  height: 40px;
  object-fit: scale-down;
`;

export const ImgIcon = styled.img`
  margin-right: 16px;
  height: 24px;
  width: 24px;
`;

export const Text = styled(TextCommon)`
  color: ${colors.Black};
  font-size: 17px;
  font-weight: bold;
  font-family: "AvertaStdCY-Bold" !important;
`;

export const TextTitle = styled(TextCommon)`
  color: ${colors.Grey};
  font-size: 13px;
`;

export const ViewProgress = styled(ViewCommon)`
  align-items: center;
  justify-content: center;
  margin: 10px
`;
