import React from "react";

export const StoreContext = React.createContext(null);

const StoreProvider = ({ children }) => {
  const [user, setUser] = React.useState(null);
  const [theme, setTheme] = React.useState({
    primaryColor: "#141ed2",
  });

  const store = {
    user: [user, setUser],
    theme: [theme, setTheme],
  };

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export default StoreProvider;
