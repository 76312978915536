import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { colors } from "theme/index";

const CheckBoxCommon = ({
  children,
  onChangeValue,
  checked = false,
  ...restProps
}) => {
  const [isChecked, setChecked] = React.useState(checked);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    onChangeValue && onChangeValue(event.target.value);
  };

  return (
    <Checkbox
      checked={isChecked}
      onChange={handleChange}
      style={{
        color: colors.Primary,
        height: "24px",
        width: "24px",
      }}
      size={"medium"}
      {...restProps}
    />
  );
};

export default CheckBoxCommon;
