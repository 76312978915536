import React from "react";
import images from "assets/images";
import {
  Container,
  ViewSchedule,
  ImgSchedule,
  TextSchedule
} from "./index.style";
import { Header } from "components/index";
import { withRouter } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import Row from "./components/Row/index";
import Status from "./components/Status/index";

const OrderDetailContainer = ({ history, location }) => {

  return (
    <Container maxWidth="sm">
      <Header title="Thông tin đơn hàng"></Header>
      <Divider />
      <Row title="Thương hiệu" value="Shopee" />
      <Row title="Giá trị" value="888.000đ" />
      <Row title="Mã đơn hàng" value="#132271747" />
      <Row title="Trạng thái đơn hàng" value="Chờ hoàn tiền" />
      <Row title="Hoàn tiền chờ duyệt" value="45.000đ" />
      <ViewSchedule>
        <ImgSchedule src={images.icOrderSchedule}></ImgSchedule>
        <TextSchedule>Quy trình hoàn tiền</TextSchedule>
      </ViewSchedule>
      <Status type={location.type}/>
    </Container>
  );
};

export default withRouter(OrderDetailContainer);
