import styled from "styled-components";
import { colors } from "theme/index";
import { ViewCommon, TextCommon } from "components/index";
export const Container = styled(ViewCommon)`
  background-color: ${colors.White};
  height: 100vh;
`;

export const ViewSchedule = styled(ViewCommon)`
  background: ${colors.White};
  /* height: 27px; */
  margin: 20px 0px 20px 20px;
  align-items: center;
  flex-direction: row;
`;

export const ImgSchedule = styled.img`
  height: 24px;
  width: 24px;
  margin: 0px 20px 0px 0px;
`;
export const TextSchedule = styled(TextCommon)`
  color: ${colors.Grey};
  font-size: 22px;
  margin: 0px;
  font-weight: 700;
  font-family: "AvertaStdCY-Bold";
`;