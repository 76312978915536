const commonStyle = {
    margin: 'auto',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0
  };
  
  const sizeItem = {
    small: '8px',
    default: '12px',
    large: '14px'
  }
  
  const sizeContainer = {
    small: '24px',
    default: '30px',
    large: '36px'
  }
  
  const borderRadiusContainerSize = {
    small: '12px',
    default: '15px',
    large: '18px'
  }
  
  export  { commonStyle, sizeItem, sizeContainer, borderRadiusContainerSize }