import React from "react";
import { ViewCallPhone1, ViewCallPhone2, Img } from "./index.style";
import images from "assets/images";
const CallPhoneCommon = (props) => {
  return (
    <>
      <a href="tel:84 0349612268">
        <ViewCallPhone2 margin={props.margin}>
          <ViewCallPhone1>
            <Img src={images.iconSupportPhoneDetail} />{" "}
          </ViewCallPhone1>
        </ViewCallPhone2>
      </a>
    </>
  );
};

export default CallPhoneCommon;
