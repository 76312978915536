import React from "react";
import {
  useStyles,
  Text,
  Container,
  BoxQuestion,
  TextHeader,
  TextDetails,
} from "./index.style";
import { CallPhone, Header } from "components/index";
import Divider from "@material-ui/core/Divider";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withRouter } from "react-router-dom";

const FAQsContainer = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="sm">
      <Header title="Câu hỏi thường gặp"/>
      <Divider />
      <BoxQuestion>
        <Text>
          Những thông tin dưới đây có thể sẽ giúp bạn giải quyết vấn đề gặp phải
          nhanh nhất có thể.
        </Text>
      </BoxQuestion>

      <div className={classes.root}>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.header}
          >
            <TextHeader>Đơn hàng tại sao không được ghi nhận?</TextHeader>
          </AccordionSummary>
          <AccordionDetails>
            <TextDetails>
              Trong hầu hết các trường hợp, bạn sẽ nhận được được thông báo xác
              nhận đơn hàng của bạn hợp lệ sau khi bạn hoàn tất thanh toán cho
              đơn hàng của bạn. Nếu bạn đã hoàn tất thanh toán, nhưng vẫn không
              nhận được bất cứ thông báo gì từ hệ thống, có thể đợi vài giờ,
              hoặc tối đa không quá 72 giờ, do độ trễ từ phía hệ thống của đối
              tác và của chúng tôi. Tuy nhiên nếu bạn vẫn không nhận được thông
              báo thì có thể vì một số lý do sau đây: Giỏ hàng của bạn đã có sẵn
              sản phẩm trước khi nhấn vào nút Mua ngay trên chúng tôi. Trong quá
              trình mua sắm, bạn tắt mở lại ứng dụng hoặc cửa sổ của nhãn hàng.
              Bạn nhấn vào quảng cáo trên website hoặc ứng dụng của nhãn hàng
              trong quá trình mua sắm với chúng tôi Bạn mua sắm không đúng danh
              mục được hoàn tiền Bạn chưa tắt ứng dụng chặn quảng cáo trên trình
              duyệt và điện thoại của bạn. Lưu ý: Chúng tôi không thể theo dõi
              các thao tác mua sắm của bạn trên hệ thống của đối tác. Hệ thống
              chỉ nhận các kết quả trả về từ hệ thống của đối tác và đối chiếu
              đơn hàng qua các thông tin về ngày giao dịch, giá trị đơn hàng và
              ngành hàng mua sắm.
            </TextDetails>
          </AccordionDetails>
        </Accordion>

        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.header}
          >
            <TextHeader>Tại sao đơn hàng của tôi bị huỷ?</TextHeader>
          </AccordionSummary>
          <AccordionDetails>
            <TextDetails>From Fear with love</TextDetails>
          </AccordionDetails>
        </Accordion>

        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.header}
          >
            <TextHeader>Tiền hoàn lại cho bạn là từ đâu?</TextHeader>
          </AccordionSummary>
          <AccordionDetails>
            <TextDetails>From Fear with love</TextDetails>
          </AccordionDetails>
        </Accordion>

        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.header}
          >
            <TextHeader>Khi nào tiền của tôi được rút về ví?</TextHeader>
          </AccordionSummary>
          <AccordionDetails>
            <TextDetails>From Fear with love</TextDetails>
          </AccordionDetails>
        </Accordion>

        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.header}
          >
            <TextHeader>Hoàn tiền chờ duyệt là gì?</TextHeader>
          </AccordionSummary>
          <AccordionDetails>
            <TextDetails>From Fear with love</TextDetails>
          </AccordionDetails>
        </Accordion>
      </div>

      <CallPhone margin={20}></CallPhone>
    </Container>
  );
};

export default withRouter(FAQsContainer);
