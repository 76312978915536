import { Header } from "components/index";
import React, { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { CheckBox, Container, TextLabel, View, Button } from "./index.style";
import * as ROUTER from "constants/routes";
import Form from "./components/Form/index";

const WithdrawConfirmContainer = ({ history, location }) => {
  const onClick = () => {
    history.push(ROUTER.WITHDRAW_COMPLETED);
  };
  return (
    <>
      <Header title={"Xác nhận rút tiền"} />
      <Container maxWidth="sm">
        <Form title="Số tiền cần rút" value={location.money + "đ"} />
        <Form title="Phí rút tiền" value="0đ" />
        <Form title="Số tiền nhận được" value={location.money + "đ"} />
        <TextLabel>Thông tin người nhận hoàn tiền: </TextLabel>
        <Form title="Họ và tên" value="Nguyễn Văn Tuấn" />
        <Form title="Số CMND/căn cước/hộ chiếu" value="201 273 288" />
        <Form title="Ngày tháng năm sinh" value="10/09/1990" />
        <View>
          <CheckBox />
          <TextLabel>
            Xác nhận các thông tin trên và đồng ý hoàn tiền về tài khoản thanh
            toán tại MB.
          </TextLabel>
        </View>
        <Button onClick={onClick}>Xác nhận</Button>
      </Container>
    </>
  );
};

export default withRouter(WithdrawConfirmContainer);
