import styled from "styled-components";
import { spacing, colors } from "theme/index";
import { ViewCommon, TextCommon, ButtonCommon } from "components/index";

export const Container = styled(ViewCommon)`
  /* flex-direction: row; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 20px 15px 15px 15px;
  margin-bottom: 126px;
`;

export const View = styled(ViewCommon)`
  background: ${colors.White};
  height: 105px;
  width: 105px;
  border-radius: 10px;
  padding: 8px;
  justify-content: space-between;
  margin: 5px;
`;

export const ViewFooter = styled(ViewCommon)``;

export const Text = styled(TextCommon)`
  color: ${colors.Black};
  font-size: 12px;
  font-weight: 600;
  opacity: 0.5;
`;

export const TextLabel = styled(TextCommon)`
  color: ${colors.Black};
  font-size: 12px;
  font-weight: 600;
  margin-top: 14px;
`;

export const TextPercent = styled(TextCommon)`
  color: ${colors.Black};
  font-size: 18px;
  font-weight: bold;
  font-family: "AvertaStdCY-Bold" !important;
`;
