import styled from "styled-components";
import {  colors } from "theme/index";
import { ViewCommon, TextCommon } from "components/index";

export const View = styled(ViewCommon)``;

export const BoxQuestion = styled(ViewCommon)`
  background: ${colors.White};
  height: 100px;
  flex-direction: row;
  justify-content: left;
  align-items: center;
`;

export const Img = styled.img`  
  width: 28px;
  height: 28px;
  margin: 20px;
`;

export const Text = styled(TextCommon)`
  color: ${colors.Black};
  font-size: 15px;
  font-weight: bold;
  font-family: "AvertaStdCY-Bold" !important;
`;
