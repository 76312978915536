import * as ApiHelper from "helpers/api.helper";

const PREFIX = "/api/v1";

export const login = async (params) => {
  return await ApiHelper.GET(PREFIX, params);
};

export const getFakeListBrand = async (params) => {
  return await ApiHelper.GET(PREFIX + "/campaigns", params);
};
