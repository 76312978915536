import styled from "styled-components";
import { ViewCommon, ButtonCommon } from "components/index";

export const Container = styled(ViewCommon)`
  /* position: absolute;
  bottom: 0;
  left: 0;
  right: 0; */
`;

export const View = styled(ViewCommon)``;

export const Button = styled(ButtonCommon)`
  margin: 18px 0px 10px 0px;
`;