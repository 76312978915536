import { Header } from "components/index";
import React, { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "./index.style";
import * as ROUTER from "constants/routes";
import TotalMoney from "./components/TotalMoney/index";
import InputMoney from "./components/InputMoney/index";
import AccountInfo from "./components/AccountInfo/index";
import Footer from "./components/Footer/index";

const WithdrawRequiredContainer = ({ history }) => {
  const [value, setValue] = useState("");
  const onClick = () => {
    history.push({ pathname: ROUTER.WITHDRAW_CONFIRM, money: value });
  };

  const onChangeMoney = (value) => {
    setValue(value);
  };

  return (
    <>
      <Header title={"Yêu cầu rút tiền"} />
      <Container maxWidth="sm">
        <TotalMoney />
        <InputMoney onChangeMoney={onChangeMoney} />
        <AccountInfo />
        <Footer onClick={onClick} />
      </Container>
    </>
  );
};

export default withRouter(WithdrawRequiredContainer);
