import images from "assets/images";
import React from "react";
import { View, TextTitle, Img, Text } from "./index.style";

const Search = ({ onClickSearch }) => {
  return (
    <>
      <TextTitle>Thương hiệu mua sắm</TextTitle>
      <View onClick={onClickSearch}>
        <Img src={images.icSearch} />
        <Text>Tìm kiếm thương hiệu</Text>
      </View>
    </>
  );
};

export default Search;
