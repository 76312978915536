import React, { useState } from "react";
import images from "assets/images";
import { BoxQuestion, Text, View, Img } from "./index.style";
import Divider from "@material-ui/core/Divider";
import * as ROUTES from "constants/routes";
import { CallPhone } from "components/index";
import { useHistory } from "react-router-dom";

const ListQuestion = () => {
  const [value, setValue] = useState(0);
  const history = useHistory();

  const onClickBoxQuestion = () => {
    history.push(ROUTES.SUPPORT_FAQs);
  };

  return (
    <>
      <BoxQuestion onClick={onClickBoxQuestion}>
        <Img src={images.iconSupportQuestion} />
        <View>
          <Text>Câu hỏi thường gặp</Text>
        </View>
      </BoxQuestion>

      <Divider />
      <BoxQuestion>
        <Img src={images.iconSupportHeadPhone} />
        <View>
          <Text>Yêu cầu hỗ trợ</Text>
        </View>
      </BoxQuestion>
      <Divider />
      <BoxQuestion>
        <Img src={images.iconSupportPhone} />
        <View>
          <Text>Hotline: 1800 00 00</Text>
        </View>
      </BoxQuestion>
      <Divider />

      <CallPhone margin={85} />
    </>
  );
};

export default ListQuestion;
